import React from 'react';
import DonateImg from '../image/support.png';

const Donate = () => {
  let url = "https://secure.actblue.com/donate/movement-4-black-lives-1";
  return (
    <div className="donate-container" style={{
      backgroundImage: `url("${DonateImg}")`
    }}>
      <div className="overlay">
        <label className="text-donate">
          On average, you need around 80 searches to gather a dollar,
          keep track of how many queries you have made by surfing the web which translates to donation money.
          You may also donate directly through the link if you want to help
          make a bigger impact
        </label>
        <a className="btn-black" href={url} target="_blank" rel="noreferrer">
          Donate
        </a>
      </div>
    </div>
  );
};

export default Donate;
