import React, { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import "../style/Carousel.css";

export const CarouselItem = ({ children, width }) => {
  return (
    <div className="carousel-item" style={{ width: width }}>
      {children}
    </div>
  );
};

const Carousel = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [paused, setPaused] = useState(false);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        updateIndex(activeIndex + 1);
      }
    }, 3000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1),
  });

  return (
    <div
      {...handlers}
      className="carousel"
      onMouseEnter={() => setPaused(true)}
      onMouseLeave={() => setPaused(false)}
    >
      <button
        className="left"
        onClick={() => {
          updateIndex(activeIndex - 1);
        }}
      >
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 535.48 535.36"
          width="100"
          height="100"
        >
          <path d="M264.3,0C413-1.1,534.8,116.9,535.9,265.1c1.1,150.5-118.4,270.8-267.7,271C120.5,536.5,0.4,417,0,269.2c0-0.3,0-0.6,0-0.8 C-0.2,121.7,119.2,1.1,264.3,0z M268.4,510.5c134.6-1,242.6-109.6,241.8-243.1C509.4,133.3,400.7,25.2,267.4,25.9 C133.3,26.7,24.7,135.5,25.4,268.4C26,402.5,135.4,511.5,268.4,510.5L268.4,510.5z" />
          <path
            className="filled"
            d="M267.4,25.9c133.3-0.7,242,107.3,242.8,241.5c0.8,133.5-107.1,242.1-241.8,243.1c-133,1-242.4-108-243.1-242.1 C24.7,135.5,133.3,26.7,267.4,25.9z M188.8,280.8h235.3c2,0,4.1,0,6.1-0.1c7.3-0.5,12.2-5.5,12.2-12.6s-5-12.2-12.1-12.6 c-2-0.1-4.1,0-6.1,0H181c2.5-2.6,3.9-4.2,5.4-5.7c28.5-28.6,57-57.1,85.6-85.6c4-3.9,7.2-8.1,5.6-14.2c-2.4-9.2-12.7-12.8-20.3-7 c-1.3,1.1-2.5,2.2-3.7,3.4L144,256.1c-9.1,9.1-9.1,15-0.1,24.1c36.7,36.7,73.4,73.3,110.1,109.9c3,3,7.9,5.7,11.9,5.8 c9.8,0.3,15.3-11.2,9.9-19.4c-1.3-1.8-2.8-3.5-4.4-5c-28.2-28.3-56.5-56.5-84.8-84.7c-1.6-1.5-3.7-2.5-5.6-3.7l1.7-2.2L188.8,280.8z"
          />
          <path d="M182.8,280.8h241.4c2,0,4.1,0,6.1-0.1c7.3-0.5,12.2-5.5,12.2-12.6s-5-12.2-12.1-12.6c-2-0.1-4.1,0-6.1,0H181 c2.5-2.6,3.9-4.2,5.4-5.7c28.5-28.6,57-57.1,85.6-85.6c4-3.9,7.2-8.1,5.6-14.2c-2.4-9.2-12.7-12.8-20.3-7c-1.3,1.1-2.5,2.2-3.7,3.4 L144,256.1c-9.1,9.1-9.1,15-0.1,24.1c36.7,36.7,73.4,73.3,110.1,109.9c3,3,7.9,5.7,11.9,5.8c9.8,0.3,15.3-11.2,9.9-19.4 c-1.3-1.8-2.8-3.5-4.4-5c-28.2-28.3-56.5-56.5-84.8-84.7c-1.6-1.5-3.7-2.5-5.6-3.7L182.8,280.8z" />
        </svg>
      </button>
      <button
        className="right"
        onClick={() => {
          updateIndex(activeIndex + 1);
        }}
      >
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 535.48 535.36"
          width="100"
          height="100"
        >
          <path
            d="M650.47,318.63c-.11,148-120.07,267.72-268,267.48C234.83,585.87,114.79,465.8,115,318.55c.21-149,120.26-268,270.1-267.79C531,51,650.58,171.68,650.47,318.63ZM383,561.36c133.71-.24,242.65-109.2,242.76-242.77s-109.53-243.26-243.1-243c-133.83.31-242.58,109.06-242.7,242.74S249.37,561.61,383,561.36Z"
            transform="translate(-114.99 -50.76)"
          />
          <path
            className="filled"
            d="M528.62,286.4C528,420.5,418.52,529.53,285.54,528.52c-134.63-1-242.6-109.6-241.78-243.13.81-134.12,109.49-242.2,242.8-241.46C420.64,44.67,529.25,153.47,528.62,286.4ZM371.2,298.84l1.71,2.23c-1.89,1.22-4.06,2.14-5.61,3.68q-42.48,42.3-84.81,84.73a34.18,34.18,0,0,0-4.44,5c-5.43,8.18.12,19.74,9.88,19.41,4.09-.15,8.91-2.82,11.94-5.82Q355.18,353.35,410,298.18c9-9,9-15-.06-24.08L300.33,164.5a39.52,39.52,0,0,0-3.67-3.42c-7.56-5.83-17.85-2.26-20.28,7-1.6,6.1,1.63,10.26,5.59,14.21q42.79,42.75,85.56,85.55c1.52,1.51,3,3.09,5.45,5.68H129.79c-2,0-4.09-.06-6.13.05-7.15.39-12.15,5.63-12.14,12.62s4.91,12.13,12.18,12.58c2,.13,4.08.08,6.13.08H371.2Z"
            transform="translate(-18.1 -18.01)"
          />
          <path
            d="M468.91,330.82h-242c-1.49,0-3,0-4.46,0-8.59-.12-14-5-13.91-12.5s5.53-12.2,14.22-12.21H470.38c-2.09-2.29-3.33-3.77-4.7-5.13q-42.78-42.81-85.57-85.59a47.77,47.77,0,0,1-4.19-4.47,12.2,12.2,0,0,1,.71-16.26,12.47,12.47,0,0,1,16.72-.91,43.71,43.71,0,0,1,3.63,3.45L506.63,306.81c8.67,8.67,8.71,14.62.15,23.18q-55,55-110.11,110a22.2,22.2,0,0,1-9.22,5.65c-5.32,1.47-9.9-.83-12.72-5.71s-2.3-9.63,1.18-14a48.67,48.67,0,0,1,3.84-4q42.79-42.8,85.63-85.54c1.42-1.4,3.46-2.18,5.2-3.25C470,332.31,469.47,331.57,468.91,330.82Z"
            transform="translate(-114.99 -50.76)"
          />
        </svg>
      </button>
      <div
        className="inner"
        style={{ transform: `translateX(-${activeIndex * 10}%)` }}
      >
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, { width: "100%" });
        })}
      </div>
      <div className="indicators">
        {React.Children.map(children, (child, index) => {
          return (
            <button
              className={`my-class ${index === activeIndex ? "active" : ""}`}
              onClick={() => {
                updateIndex(index);
              }}
            ></button>
          );
        })}
      </div>
    </div>
  );
};

export default Carousel;
