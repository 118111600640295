import React from 'react';
import "../style/Terms.css";
import Logo from '../image/logo.png';

const buttonClickHandler = () => {
    window.location.href = '/'
    window.open("/terms", "_blank")
  }

const Terms = () => {
    return (
        <div className="body">
            <div className="container-term">
                <div className="logo-terms">
                <a href="/" onClick={buttonClickHandler}>
                    <img className="logo" src={Logo} alt="logo" />
                </a>
                     <h1>Black Search Matter License Agreement</h1>
                     <div></div>
                </div>
                <h3>This End User License ("Agreement" or "EULA") is a legal binding agreement between you ("user" or "you" )
                    and Imali N.I Media LTD. ("Company" or "us" or "our" or "we"). This Agreement sets forth the terms in which
                    you may use our new tab extensions or search extensions (as detailed below), including any revisions,
                    improvements, new releases and related documentation in connection thereto ("Browsing Product" or
                    "Product").</h3>
                <ul>
                    <li>Please read the terms and conditions of this agreement carefully before downloading, installing or using
                        any product or features provided by us. By choosing the "I accept" or "add to chrome" button, you hereby
                        acknowledge that you have read, understood, accepting and agreeing to be bound by all the terms and
                        conditions of this EULA and the terms. This EULA together with the privacy policy available at
                        https://newtabtv.com/privacy (together "terms") govern your use of the product and service. You further
                        acknowledge that these terms constitute a binding and enforceable legal contract between the company and
                        you which further enforces class action waiver and arbitration provision as detailed in the dispute
                        resolution section herein below. If you do not agree to these terms in its entirety, do not download,
                        install or use the product or service in any manner. Any use of the product or service by you under such
                        circumstances will be considered as a violation of our legal rights.</li>
                    <li>We reserve the right to periodically amend or revise these Terms from time to time, at our sole
                        discretion. Any changes to the Terms will become effective immediately upon the display of the revised
                        Terms. The most recent version of the Terms will always be posted. If we do make any changes, the
                        updated date at the top of the Agreement will be reflected in the "Last Modified" heading. Your
                        continued use of the Browsing Product or our services, following the display of such modified Terms,
                        constitutes your acknowledgement and consent of such amendments and your agreement to be bound by the
                        terms of such amendments. In the event of a material change we will make best efforts to send you a
                        written notification.

                        You hereby warrant that you are eligible to enter into these Terms and you are not prohibited by any
                        authorized authority, judicial order or law into entering in any agreement; you have all proper
                        authorization, if you are acting on behalf of a corporation, to enter into these Terms.</li>
                    <li>In addition, you represent and warrant that you are at least 13 years of age and of legal competence to
                        enter into these Terms. Children under 13 are prohibited from using the Product. In the event that we
                        become aware that a user under the age of 13 has shared any information, we will discard such
                        information. If you have any reason to believe that a child under the age of 13 has shared any
                        information with us, please contact us at: contact@imali.media. If you are under 18, please be sure
                        to read the Terms with your parents or legal guardians and ask questions about things you do not
                        understand.</li>
                    <li>Following your acceptance of this EULA, the Product will be installed on your computer. The Product is a
                        software that changes your browsers' new tab setting or features that you may choose to install and may
                        affect your browsers' search settings. These features may include, as applicable, changes in your
                        browsers' (i) new tab; (ii) default search engine; and (iii) home page.
                        The unique features we provide enable you to enjoy a large variety of beautiful backgrounds for your new
                        tabs, including the option to upload your own images as backgrounds, and contains easy to function,
                        designed shortcuts to top websites that you can easily customize to your own interests ("Dials") as well
                        as music players, bookmark manager, to-do-list and other great functionalities.

                        We also provide features that enhance your search experience, links you to top shopping websites, sport
                        channels, social media websites, various media content networks and free games, etc. we may use some
                        anti-fraud and robotic behaviour methods such as Captcha, redirects and other solution that we will not
                        any way harm your user expirience or privacy.

                        The Product includes links to third party sites or content, provided by our Service Providers (as
                        defined in our Privacy Policy (https://newtabtv.com/privacy), such as: Dials, advertisement or search
                        feed results (collectively, "Third Party Content"). The Product may also include desktop short cuts to
                        these Third Party Content.

                        Any use by you of Third Party Content is subject to the terms and conditions of the Service Provider.
                        You acknowledge that we have no control over and assume no responsibility for Third Party Content. You
                        assume all responsibility and risk in connection with your use of Third Party Content and we hereby
                        disclaim any and all liability to you or any third party in connection thereto. We have no obligation to
                        examine, review or monitor Third Party Content and are not responsible for the accuracy, completeness,
                        appropriateness or legality of Third Party Content.

                        The availability of any Third Party Content as part of the Product is not an endorsement, authorization
                        or approval of such Third Party Content, and you hereby waive any legal or equitable rights or remedies
                        you have or may have against us with respect thereto. In addition, you acknowledge and agree that
                        certain Third Party Content may cease to be available to you at any time without providing any notice.
                    </li>
                    <li>Subject to your compliance with the terms and conditions of this Agreement, the Company hereby grants
                        you a limited, non-exclusive, revocable, non-sub licensable, non-transferable license (without the right
                        to sub-license), to access, download and install the most current generally available version of the
                        Browsing Products (including all updates thereto) solely for your lawful, personal, and non-commercial
                        use. Your license hereunder is limited not to allow others to use, copy or evaluate copies, and the
                        license granted herein shall not be copied, shared, distributed, re-sold, and offered for re-sale,
                        transferred or sub-licensed in whole or in part.</li>
                    <li>You will install the Browsing Product only in accordance with the instructions of the Company. Except as
                        expressly provided under the Agreement, you may not, nor may he enable anyone else to, directly or
                        indirectly: (i) use or display the Browsing Product together with material that is pornographic, racist,
                        vulgar, obscene, defamatory, libelous, abusive, promoting hatred, discriminating; (ii) commercially
                        distribute, rent, lease, market, sublicense, resell or otherwise transfer the Browsing Product or any
                        portion thereof; (iii) copy or modify decompile, disassemble, create any derivative works or reverse
                        engineer the Browsing Product or any portion thereof including in cases it is made available with any
                        other software, product or program, (iv) remove any identification, including copyright, trademark,
                        patent or other notices, contained in or in connection with the Browsing Product; or (v) use the
                        Company's name, logo or trademarks without its prior written consent; or (vi) use any automated devices,
                        such as spiders, robots or data mining techniques, to catalog, download, store, reproduce, or distribute
                        content available in the Licensed Application, or to manipulate the Licensed Application in any manner
                        not consistent with its or their intended purpose(s). For avoidance of all doubt, Company reserves all
                        rights not expressly granted in this EULA, and disclaims any implied license, including implied licenses
                        to copyrighted materials, trademarks or patents. At any time, the Company according to its sole
                        discretion may modify or discontinue or suspend or terminate User's ability to use the Browsing Product
                        or any portion thereof hereunder (including content or services provided through therein) or terminate
                        any license hereunder.

                        We reserve the right to investigate occurrences which may involve such violations, and may involve and
                        cooperate with law enforcement authorities in prosecuting users who have participated in such
                        violations.</li>
                    <li>You hereby represent and warrant that: (i) you are either the owner or an authorized user of the
                        computer where the Product is installed (ii) you will only use the Product as permitted under this EULA;
                        (iii) you will use the Product in full compliance with all applicable laws, rules and regulations and
                        industry best practices; (iv) you will not use the Product for any fraudulent or inappropriate purpose;
                        (v) you are not located in a country that is subject to a U.S. Government embargo or that has been
                        designated by the U.S. Government as a "terrorist supporting" country; and (vi) you are not listed on
                        any list of U.S. Government list with respect to prohibited or restricted parties.</li>
                    <li>We reserve the right to add or remove features or functions to or from the Product. When installed on
                        your computer, the Product periodically communicate with our servers to request automatic updates when
                        we release a new version of the Product, or when we make new features available. you hereby agree that
                        the Company may automatically download and install updates to the Product, from time to time, without
                        prior notification. these updates are designed to improve, enhance and further develop the Product and
                        may take the form of bug fixes, enhanced functions, new software modules and completely new versions.
                        you agree to receive such updates as part of your use of the Product. In the event, we believe that such
                        updates or upgrades shall materially affect your use of the Product, we will make best efforts to notify
                        you. Updates to stable the Product for Chrome releases are handled by the Google Web Store website and
                        are subject to the Google Privacy Policy.</li>
                    <li>You acknowledge that all right and interest in the Browsing Product trademarks, service marks, the
                        Product, including all associated components, code, protocols, software and documentation provided to
                        you by us in conjunction with our services, any related features or derivatives thereof improvements and
                        modifications thereto, including associated intellectual property rights, evidenced by or embodied in or
                        attached/connected/related to the product, are the Company's property or the property of our licensors,
                        and are protected by international copyright, trademarks, patents and other proprietary rights and laws
                        relating to trade secrets, and any other intellectual property or proprietary rights recognized in any
                        country or jurisdiction worldwide, including, without limitation, moral or similar rights. You may not
                        use, delete, alter, or remove any copyright, trademark, or other proprietary rights notice we have
                        placed on the Product or associated components. This EULA does not grant to you an interest in or to the
                        Product, but only a limited right of use in accordance with the Terms herein. Nothing in this Agreement
                        constitutes a waiver of our intellectual property rights under any law. All rights not expressly granted
                        hereunder are expressly reserved to us and our licensors.

                        In addition, you acknowledge and agree that the Browsing Product may provide you with Third Party
                        Content which includes copyrighted material, trade secrets, patents (registered or pending), designs,
                        information, methods, specifications, graphics, text, logos and other proprietary material ("Third Party
                        IP"). You shall not, shall not attempt to, and shall not allow any third party, to use, copy,
                        distribute, display, execute publicly, make available to the public, reduce to human readable form,
                        adapt, sublicense, make any commercial use, sell, rent, lend, process, combine with other software,
                        translate, modify, reverse engineer, disassemble or decompile any such content. Nor may he create any
                        derivative works or other works that are based upon or derived from the aforementioned content in whole
                        or in part. Intellectual Property law including, Copyright law and certain international copyright
                        treaty provisions protect all parts of the Browsing Product and all content provided therein or
                        therewith (including all products and services). No program, code, part, image, audio sample, or text
                        may be copied, or used in any way by the user except for the applicable Purpose. The Company respects
                        and expects its users to respect the rights of copyright holders. On notice, we will act appropriately
                        to remove content that infringes the copyright rights of others. We reserve the right to disable the
                        access to Browsing Products or other services by anyone who uses them to repeatedly infringe the
                        intellectual property rights of others. If you believe a Browsing Product, or elements, infringe your
                        copyright rights, please contact us at: contact@imali.media

                        Further, you acknowledge and agree that the technology manifested in the operation of the Product
                        constitutes our, and our Third Parties' valuable trade secrets and know-how and to the extent you
                        discover any such trade secrets, you will not disclose them to any third party. Any disclosure or
                        unauthorized use thereof will cause us irreparable harm and loss</li>
                    <li>You expressly acknowledge and agree that your use of the browsing product and any services, content
                        (including third party content and the browsing product services) provided therein is at his sole risk
                        (including, without limitation, any loss of data or other damage to device) and that the entire risk as
                        to satisfactory quality, performance, accuracy and effort is with the user. The content available on the
                        browsing product may include inaccuracies or errors. the company does not guarantee the accuracy of, and
                        disclaim all liability for any errors or other inaccuracies relating to the content displayed on the
                        browsing product including to the products and services offered via the browsing product. To the maximum
                        extent permitted by applicable law, the browsing product and any services, product or content provided
                        therein are provided on an "as is" without warranty of any kind. The company hereby disclaims all
                        warranties and conditions with respect to the browsing product and any services, product or content
                        provided therein, either express, implied or statutory, including, but not limited to, the implied
                        warranties or conditions of merchantability, of satisfactory quality, of fitness for a particular
                        purpose, of accuracy, of quiet enjoyment, and non-infringement of third party rights. The company does
                        not warrant that the browsing product and its services will meet the user's requirements. if the user is
                        dissatisfied with any of the content contained in the browsing product or with the terms of this eula,
                        his sole and exclusive remedy is to discontinue accessing and using the browsing product and any
                        services product and content provided therein.</li>
                    <li>User shall defend, indemnify, hold harmless, and defend the Company, its officers, directors,
                        shareholders, employees, sub-contractors, agents and distributors or any third party providing content
                        or services in the Browsing Product, or in connection thereto, from and against any claims, losses,
                        debts, obligations, liabilities, costs or expenses (including but not limited to reasonable attorney's
                        fees) arising from or incurred as a result of, related to or are based on (i) User's use of the Browsing
                        Product (ii) violation of these Terms.</li>
                    <li>Company and user may terminate this Agreement at any time in its sole discretion. If you wish to
                        terminate your use of the Product and this Agreement, please uninstall and remove the Product and cease
                        using the Browsing Product Services immediately. To uninstall the Product please use your standard
                        uninstall processes that are available through your Browser's Settings, and remove the extension.
                        Uninstall instructions are available here: https://newtabtv.com/uninstall. We may terminate your access
                        to Product or our services at any time, with or without cause and with or without notice, effective
                        immediately. Any termination may result in the destruction of all information and data associated with
                        your use of the Product.</li>
                    <li>By Using the Browsing Product, you may enable us or third parties to access, use, and collect a variety
                        of information, both personal and non-personal, regarding your Internet Browser, your browsing habits,
                        and information about your computer including by usage of cookies. For more details on the data
                        collected and privacy methods we recommend you read our Privacy Policy https://tabitab.com/privacy. Our
                        Privacy Policy contains information about the data protection policies and explains how we accesses,
                        collect, use, or share information. By downloading and using the Product or any of our services, you
                        consent to the Privacy Policy.</li>
                    <li>For any dispute you have with us, you agree to first contact us at: contact@imali.media and attempt
                        to resolve the dispute with us informally. If we were not able to resolve the dispute with you
                        informally, we each agree by these enforceable Terms, to resolve any claim, and unless otherwise
                        required by a mandatory law dispute or controversy arising out of or in connection with or relating to
                        these Terms by binding and exclusively arbitration by the American Arbitration Association ("AAA"). ALL
                        CLAIMS MUST BE BROUGHT IN THE PARTIES' INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN
                        ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING AND THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE
                        PERSON'S CLAIMS. YOU AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND US ARE EACH WAIVING THE RIGHT TO
                        A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.

                        You must include your name and residence address, and a clear statement that you want to opt out of this
                        arbitration agreement. This arbitration agreement will survive the termination of these Terms. These
                        Terms are governed by and construed in accordance with the laws of the State of New York, without giving
                        effect to any principles of conflicts of law and will specifically not be governed by the united nations
                        conventions on contracts for the international sale of goods, if otherwise applicable. For any action at
                        law or in equity relating to the arbitration provision of these Terms and Conditions, you agree to
                        resolve any dispute you have with exclusively in a state or federal court located in New York, Manhattan
                        and to submit to the personal jurisdiction of the courts located in New York County for the purpose of
                        litigating all such disputes. Any cause of action you might have relating to the service is limited in
                        time to one (1) year from the arising incident, and will be permanently barred afterwards.</li>
                    <li>These Terms, constitutes the entire understanding between the parties with respect to the use of the
                        Product and our services. If any provision of these Terms is held to be unenforceable, such provision
                        shall be reformed only to the extent necessary to make it enforceable and such decision shall not affect
                        the enforceability of such provision under other circumstances, or of the remaining provisions hereof
                        under all circumstances. Failure of the Company to enforce any rights or to take action against you in
                        the event of any breach hereunder shall not be deemed as a waiver of such rights or of subsequent
                        actions in the event of future breaches. This EULA and any right granted herein may not be assigned by
                        you without the prior written consent of the Company.

                        By using our product users are also agreeing to be bound by the YouTube Terms of Service.</li>
                    <li>If you have any questions please contact us by email or postal mail as follows:
                        Owned and operated by: Imali N.I Media LTD, HaBarzel 3 Tel-Aviv, Israel 6971006
                        Contact us via e-mail: contact@imali.media</li>
                </ul>
            </div>
        </div>
    );
};

export default Terms;
