import React, { useState, useEffect } from 'react';
import Logo from '../image/logo.png';
import Info from '../image/heart.svg';
import {getqcount, initq,setcount} from '../common/qcount';

const Hero = () => {
    const [count, setCount] = useState("0")
    useEffect(() => {
        async function fetchData() {
            let c = await initq()
            setCount(c)
        }
        fetchData()
    }, [count])
    return (
        <div className="hero-black">
            <div className="counter-top">
                <img className="info" src={Info} alt="info" />
                <p className="searches">My searches</p><span>{count}</span>
            </div>
            <div className="logo-container">
                <img className="logo" src={Logo} alt="logo" />
            </div>

            <div className="mid-hero">
                <form action="https://serp.bsm.life/" method="GET" target="_blank" onSubmit={handleSubmit}>
                    <input type="hidden" name="id" value="abec38260d868b372b5b60b634692e36118872bb"/>
                    <div className="search-line">
                        <input type="text" name="q" id="input-search" />
                        <input type="submit" value="Search" className="btn-search"></input>
                    </div>
                    <label className="text-label">Donate immediatly to the Black Lives Matter cause by searching here</label>
                </form>
            </div>
            <div className="bottom-hero ">
                <div className="in-line">
                    <p>With your support, we raised</p>
                </div>
                <h2>$245 323</h2>
                <p>for BLM cause</p>
            </div>
        </div>
    );

    async function handleSubmit(e) {
        let ans = await getqcount()
        await setcount(ans)
        setCount(ans + 1)
    }
};

export default Hero;
