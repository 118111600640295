import React from 'react';
import { useLocation } from "react-router-dom";
import p from "../constants/prod";

const Dlp = () => {
    const location = useLocation();
    React.useEffect(() => {
        if (location.pathname === "/dlp") {
            p.InstantClick();
        }
      }, [location]);
    
    return (<div></div>)
};

export default Dlp;
