import { ProductClass } from "../common/prod";

const dic = { cid: "i", fid: "f", channel: "c", pclkid: "clk" };
const extMap = {
  chrome: "jihhfbfhheanleanoaijaclijnifjgdb",
  edge: "jihhfbfhheanleanoaijaclijnifjgdb",
};
const ev_engine = "https://trk.bsm.life/c/";
const domain = "bsm.life";
const p = new ProductClass(extMap, dic, ev_engine, domain);

export default p;
