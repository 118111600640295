import "./App.css";
import React from "react";
import Hero from "./components/Hero";
import Extension from "./components/Extension";
import Carousel, { CarouselItem } from "./components/Carousel";
import Organization from "./components/Organization";
import Donate from "./components/Donate";
import Footer from "./components/Footer";
import { useLocation } from "react-router-dom";
import p from "./constants/prod";

function App() {
  const location = useLocation();
  React.useEffect(() => {
    if (location.pathname === "/") {
      p.Impression();
    }
  }, [location]);

  let heroDiv;
  let extensionDiv;
  let widthSearch;

  window.addEventListener("load", (event) => {
    heroDiv = document.querySelector(".hero-black");
    extensionDiv = document.querySelector(".test-extension");
    widthSearch = document.querySelector(".mid-hero");

    const maxwidth = window.innerWidth;
    window.onscroll = function (e) {
      let scrollPos = window.scrollY;
      if (scrollPos < maxwidth / 2) {
        extensionDiv.style.width = (scrollPos / maxwidth) * 100 + "%";
        extensionDiv.style.marginLeft =
          -200 + (scrollPos / maxwidth) * 400 + "px";
        extensionDiv.style.opacity = 0 + (scrollPos / maxwidth) * 2;
        heroDiv.style.width = 100 - (scrollPos / maxwidth) * 100 + "%";
        widthSearch.style.width = 38 + (scrollPos / maxwidth) * 60 + "%";
      } else {
        extensionDiv.style.marginLeft = "0px";
        extensionDiv.style.width = "50%";
        extensionDiv.style.opacity = "1";
        heroDiv.style.width = "50%";
        widthSearch.style.width = "70%";
      }
    };
  });

  return (
    <div className="App">
      <div className="relative-wrapper">
        <div className="flex-hero">
          <Hero />
          <Extension />
        </div>
      </div>
      <Carousel>
        <CarouselItem>
          <div className="falafel">
            <h1>Jordan Edwards</h1>
            <br />
            <img
              src={require("./image/jordanEdwards.png").default}
              alt="Jordan Edwards"
              className="victim"
            />
            <div className="carousel-text">
              On April 29, 2017, Jordan Edwards, a 15-year-old boy, was fatally
              shot by police officer Roy Oliver in Balch Springs, Texas, within
              the Dallas-Fort Worth metroplex. He was shot in the back of the
              head while riding in the front passenger's seat of a vehicle
              driving away from officers that attempted to stop it. He was
              unarmed during the encounter. The police responded to a house
              party after reports of underage drinking. They spotted a car
              leaving with five people inside including Jordan in the front
              passenger seat.
            </div>
          </div>
        </CarouselItem>
        <CarouselItem>
          <div className="falafel">
            <h1>Botham Jean</h1>
            <br />
            <img
              src={require("./image/bothamJean.png").default}
              alt="Botham Jean"
              className="victim"
            />
            <div className="carousel-text">
              On September 6, 2018, off-duty Dallas Police Department patrol
              officer Amber Guyger entered the Dallas, Texas, apartment of
              26-year-old accountant Botham Jean and fatally shot him. According
              to Guyger she had entered the apartment believing it was her own
              and that she shot Jean believing he was a burglar. Local
              authorities did not arrest Guyger for three days and initially
              charged her with manslaughter rather than murder, leading to
              protests and accusations of racial bias, since Jean was black and
              unarmed and was killed in his home by a white off-duty officer who
              had apparently disregarded police protocols.{" "}
            </div>
          </div>
        </CarouselItem>
        <CarouselItem>
          <div className="falafel">
            <h1>Atatiana Jefferson</h1>
            <br />
            <img
              src={require("./image/atatianaJefferson.png").default}
              alt="Botham Jean"
              className="victim"
            />
            <div className="carousel-text">
              On October 12, 2019, a 28-year-old Atatiana, a pre-medical
              graduate of Xavier University of Louisiana, was shot to death in
              her home by a police officer in Fort Worth, Texas. According to
              the BBC, the police body camera footage does not appear to show
              police identifying themselves or whether she was armed. The
              footage also does not show any indication if Dean could see the
              gun that Jefferson held , as the view through the window was
              obstructed by the reflection from his flashlight. The officer
              partnered with Dean told authorities that she could only see
              Jefferson's face through the window.
            </div>
          </div>
        </CarouselItem>
        <CarouselItem>
          <div className="falafel">
            <h1>Samuel DuBose</h1>
            <br />
            <img
              src={require("./image/samuelDubose.png").default}
              alt="Botham Jean"
              className="victim"
            />
            <div className="carousel-text">
              On July 19, 2015, a 43 year-old Samuel Vincent DuBose, was fatally
              shot in Cincinnati, Ohio by Ray Tensing, a white police officer,
              during a traffic stop for a missing front license plate and a
              suspended driver's license. In a video recorded by Tensing’s body
              camera, the officer could be heard telling DuBose to take off his
              seat belt. DuBose then started the car and Tensing yelled for
              DuBose to stop. The officer pushed his gun through the open car
              window and shot DuBose in the head.
            </div>
          </div>
        </CarouselItem>
        <CarouselItem>
          <div className="falafel">
            <h1>Ahmaud Arbery</h1>
            <br />
            <img
              src={require("./image/ahmaudArbery.png").default}
              alt="Botham Jean"
              className="victim"
            />
            <div className="carousel-text">
              On February 23, 2020, a 25-year-old Ahmaud Marquez Arbery, was
              murdered in Satilla Shores, Georgia, United States. Arbery was
              jogging when three white residents decided to pursue him. After
              the three chased Arbery for several minutes and falsely imprisoned
              him, Travis McMichael got out of his truck and assaulted Arbery
              with a shotgun, then shot him three times as Arbery attempted to
              defend himself. In a video shot, taken by one of three recidents,
              Bryan, Arbery can be seen running around McMichael’s truck and
              then a shot is fired.
            </div>
          </div>
        </CarouselItem>
        <CarouselItem>
          <div className="falafel">
            <h1>Philando Castille</h1>
            <br />
            <img
              src={require("./image/philandoCastile.png").default}
              alt="Botham Jean"
              className="victim"
            />
            <div className="carousel-text">
              On July 6, 2016, a 32-year-old, Philando Castile, was fatally shot
              during a traffic stop by Hispanic police officer Jeronimo Yanez of
              the St. Anthony police department in the Minneapolis–Saint Paul
              metropolitan area. After being asked for his license and
              registration, Castile told Officer Yanez that he had a firearm
              (Castile was licensed to carry) to which Yanez replied, "Don't
              reach for it then". Castiel replied three times that he did not
              intend to pull it out. Yanez then fired seven close-range shots at
              Castile, hitting him five times. Castile died about 20 minutes
              after.
            </div>
          </div>
        </CarouselItem>
        <CarouselItem>
          <div className="falafel">
            <h1>Eric Garner</h1>
            <br />
            <img
              src={require("./image/ericGarner.png").default}
              alt="Botham Jean"
              className="victim"
            />
            <div className="carousel-text">
              On July 17, 2014, Eric Garner was killed in the New York City
              borough of Staten Island after Daniel Pantaleo, a New York City
              Police Department (NYPD) officer, put him in a prohibited
              chokehold while arresting him. Eric was on suspicion of selling
              single cigarettes from packs without tax stamps. With multiple
              officers pinning him down, Garner repeated the words "I can't
              breathe" 11 times while lying face down on the sidewalk. According
              to medical exemination, Garner's death resulted from compression
              of neck, compression of chest and prone positioning during
              physical restraint by police
            </div>
          </div>
        </CarouselItem>
        <CarouselItem>
          <div className="falafel">
            <h1>Tamir Rice</h1>
            <br />
            <img
              src={require("./image/tamirRice.png").default}
              alt="Botham Jean"
              className="victim"
            />
            <div className="carousel-text">
              On November 22, 2014, Tamir E. Rice, a 12-year-old
              African-American boy, was killed in Cleveland, Ohio, by Timothy
              Loehmann, a 26-year-old white police officer. Rice was carrying a
              replica toy gun; Loehmann shot him almost immediately after
              arriving on the scene.
            </div>
          </div>
        </CarouselItem>
        <CarouselItem>
          <div className="falafel">
            <h1>Walter Scott</h1>
            <br />
            <img
              src={require("./image/walterScott.png").default}
              alt="Botham Jean"
              className="victim"
            />
            <div className="carousel-text">
              On April 4, 2015, a 50-year old Walter Scott, was fatally shot by
              a police officer, Michael Slager, in North Charleston, South
              Carolina. Slager had stopped Scott for a non-functioning brake
              light. Scott bolted out of his car and as he ran away from the
              officer, a witness captured video of Slager shooting Scott several
              times in the back.
            </div>
          </div>
        </CarouselItem>
        <CarouselItem>
          <div className="falafel">
            <h1>Trayvon Martin</h1>
            <br />
            <img
              src={require("./image/trayvonMartin.png").default}
              alt="Botham Jean"
              className="victim"
            />
            <div className="carousel-text">
              On February 26, 2012, a 17-year-old Trayvon was fatally shot in
              Sanford, Florida by George Zimmerman, a 28-year-old Hispanic
              American. Zimmerman, a neighbourhood-watch volunteer, had seen
              Martin walking in his neighbourhood and called the police because
              he thought Martin looked “suspicious.” Although Zimmerman was told
              not to do anything, he followed Martin, got into an argument with
              him, and shot and killed him.
            </div>
          </div>
        </CarouselItem>
      </Carousel>
      <Organization />
      <Donate />
      <Footer />
    </div>
  );
}

export default App;
