import React from 'react';

const buttonClickHandler = () => {
  window.location.href = '/terms'
  window.open("/terms", "_blank")
}

const Footer = (props) => {
  let baseStyle = {
    transform: 'translate(85%, 85%) scale(3)',
  }
  let enhancedStyle = {
    transformOrigin: 'center center'
  }
  return (
    <div className="test-black">
      <div className="scroll">
        <div className="m-scroll">
          <span className="blm-foot">BLACK LIVES MATTER </span>
          <span className="blm-foot">BLACK LIVES MATTER </span>
          <span className="blm-foot">BLACK LIVES MATTER </span>
          <span className="blm-foot">BLACK LIVES MATTER </span>
        </div>
      </div>
      <div className="flexing">
        <a className="hover-1" href="mailto:contact@imali.media">Contact</a>

        <a className="hover-2" href="/terms" onClick={buttonClickHandler}>Terms &amp; Conditions</a>
        
        <a className="hover-3" href="/privacy">Privacy Policy</a>
      </div>
      <div className="contact-line">
        <input type="text" id="input-email" placeholder="Email Adress" />
        <input type="submit" value="Suscribe Now" className="btn-subway"></input>
      </div>
      <div className="social-media">
        <div className="twitter">
          <svg viewBox="0 0 112.196 112.196" width="36px" height="36px">
            <g>
              <circle fill="#252525" cx="56.098" cy="56.098" r="56.098"></circle>
              <path style={{ ...baseStyle, ...enhancedStyle }} fill="#fff" d="M36,17.1c-0.9,0.4-2,0.8-3,0.9c1-0.6,2.6-1.9,3-3c-1,0.6-2.7,1.2-3.8,1.4C31.3,15.4,30,15,28.6,15	c-2.7,0-4.6,2.3-4.6,5v2c-4,0-7.9-3-10.3-6c-0.4,0.7-0.7,1.6-0.7,2.5c0,1.8,1.7,3.7,3,4.5c-0.8,0-2.3-0.6-3-1c0,0,0,0,0,0.1	c0,2.4,1.7,4,3.9,4.4C16.5,26.6,16,27,14.1,27c0.6,1.9,3.8,3,5.9,3c-1.7,1.3-4.7,2-7,2c-0.4,0-0.6,0-1,0c2.2,1.4,5.2,2,8,2	c9.1,0,14-6.9,14-13.4c0-0.2,0-0.9,0-1.1C35,18.8,35.3,18.1,36,17.1" />
            </g>
          </svg>
        </div>
        <div className="facebook">
          <svg viewBox="0 0 112.196 112.196" width="36px" height="36px">
            <g>
              <circle fill="#252525" cx="56.098" cy="56.098" r="56.098" />
              <path fill="#fff" d="M70.201,58.294h-10.01v36.672H45.025V58.294h-7.213V45.406h7.213v-8.34
          c0-5.964,2.833-15.303,15.301-15.303L71.56,21.81v12.51h-8.151c-1.337,0-3.217,0.668-3.217,3.513v7.585h11.334L70.201,58.294z" />
            </g>
          </svg>
        </div>
        <div className="instagram">
          <svg viewBox="0 0 112.196 112.196" width="36px" height="36px">
            <g>
              <circle id="XMLID_115_" fill="#252525" cx="56.098" cy="56.097" r="56.098" />
              <path fill="#ffffff" d="M79.124,22.506h-46.05c-5.83,0-10.56,4.73-10.56,10.57v46.05c0,5.83,4.73,10.56,10.56,10.56h46.05
          c5.83,0,10.56-4.73,10.56-10.56v-46.05C89.684,27.236,84.954,22.506,79.124,22.506z M71.983,30.276l8.44-0.02h0.02
          c0.81-0.01,1.47,0.65,1.47,1.45v8.47c0,0.81-0.65,1.46-1.46,1.46l-8.43,0.03c-0.81,0-1.46-0.65-1.46-1.45l-0.03-8.47
          C70.523,30.936,71.173,30.286,71.983,30.276z M45.804,50.326c0.22-0.38,0.45-0.76,0.71-1.12c2.15-2.98,5.64-4.93,9.58-4.93
          c3.95,0,7.44,1.95,9.59,4.93c0.26,0.36,0.49,0.74,0.71,1.13c0.96,1.7,1.53,3.67,1.53,5.76c0,6.52-5.31,11.82-11.83,11.82
          c-6.52,0-11.82-5.3-11.82-11.82C44.273,54.006,44.834,52.036,45.804,50.326z M83.144,76.706c0,3.55-2.89,6.44-6.44,6.44h-41.21
          c-3.55,0-6.44-2.89-6.44-6.44v-27.5h10.03c-0.25,0.61-0.47,1.25-0.65,1.89c-0.46,1.59-0.7,3.27-0.7,5
          c0,10.13,8.24,18.37,18.36,18.37c10.13,0,18.37-8.24,18.37-18.37c0-1.73-0.25-3.41-0.7-5c-0.19-0.64-0.4-1.28-0.65-1.89h10.03
          V76.706z" />
            </g>
          </svg>
        </div>
      </div>
      <div className="copyright-line">
        <p>All the rights reserved to © imali media LTD 2022.</p>
      </div>
    </div>
  );
};

export default Footer;
