import React from "react";
import Gif from "../image/giphy.gif";
import p from "../constants/prod";

const Extension = () => {
  return (
    <div className="test-extension">
      <h1 className="content-title">Get the BSM extension</h1>
      <div className="gif">
        <img className="gify" src={Gif} alt="gif" />
      </div>
      <p className="tis-80">
        Help the “Black Lives Matter” cause simply by downloading the "Black
        Search Matter" extension and start searching through your address bar.
        You will be able to see in realtime how much money your searches made
        for the cause
      </p>
      <button
        onClick={() => {
          p.Click();
        }}
        className="extension-btn"
      >
        Download
      </button>
    </div>
  );
};

export default Extension;
